import React, { useState, useEffect, useRef } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { CSVLink } from "react-csv";
import SalesDetail from "./SalesReportComponent/SalesDetail";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const SalesReport = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [result, setResult] = useState(null);
  const reportRef = useRef();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [salesDetail, setSalesDetail] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = async (date) => {
    try {
      const response = await axios.post(
        `${apiUrl}inventory/sales-report-detail/`,
        { date }
      );
      setSalesDetail(response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching sales details:", error);
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
        console.log(">>>>>>>", response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {};
    if (startDate) data.start_date = startDate;
    if (endDate) data.end_date = endDate;
    if (selectedUser && selectedUser !== "all") data.shop = selectedUser;

    if (Object.keys(data).length === 0) {
      alert("Please select at least one filter.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}inventory/sales-report/`,
        data
      );
      setResult(response.data.sale_records);
      console.log("sales response : ", response.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handlePrint = () => {
    const printContent = reportRef.current;
    const WindowPrint = window.open("", "", "width=900,height=650");
    WindowPrint.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    WindowPrint.focus();
    WindowPrint.print();
    WindowPrint.close();
  };

  const headers = [
    { label: "Date", key: "date" },
    { label: "Sales", key: "total_sales" },
  ];

  const csvData = result
    ? result.map((record) => ({
        date: record.week_range, // Format date to YYYY-MM-DD
        total_sales: record.total_sales,
      }))
    : [];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex flex-row justify-content-evenly">
          <div className="d-flex flex-column">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit">Submit</button>
        </div>
      </form>
      {result && (
        <>
          <div className="d-flex justify-content-end mb-3">
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"sales-report.csv"}
              className="btn btn-primary me-2"
            >
              Export to CSV
            </CSVLink>
            <Button className="btn btn-secondary" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div ref={reportRef}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date Range</th>
                  <th>Sales</th>
                </tr>
              </thead>
              <tbody>
                {result.map((record) => (
                  <tr key={record.id}>
                    <td
                      onClick={() => handleOpenModal(record.week_start)}
                      style={{ cursor: "pointer" }}
                    >
                      {record.week_range}
                    </td>
                    <td>${record.total_sales}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sales Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!salesDetail ? (
            <div></div>
          ) : (
            <SalesDetail salesDetail={salesDetail} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SalesReport;
