import React from "react";
import { Table } from "react-bootstrap";

const SalesDetail = ({ salesDetail }) => {
  return (
    <div>
      <h5><strong>Sales Summary</strong></h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cashier</th>
            <th>Client</th>
            <th>Total Sales</th>
            <th>Cash Amount</th>
            <th>Card Amount</th>
            <th>Transfer Amount</th>
          </tr>
        </thead>
        <tbody>
          {salesDetail.sales.map((sale) => (
            <tr key={sale.id}>
              <td>{sale.cashier__username}</td>
              <td>{sale.client__FirstName}</td>
              <td>${sale.total_sales}</td>
              <td>${sale.cash_amount}</td>
              <td>${sale.card_amount}</td>
              <td>${sale.transfer_amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h5><strong>Sales Details</strong></h5>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Quantity Sold</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {salesDetail.sales_details.map((detail) => (
            <tr key={detail.sale_id}>
              {detail.product_id ? (
                <>
                  <td>Product</td>
                  <td>{detail.product_id__productName}</td>
                  <td>{detail.quantity_sold}</td>
                  <td>${detail.total_price}</td>
                </>
              ) : (
                <>
                  <td>Service</td>
                  <td>{detail.service_id__name}</td>
                  <td>{detail.quantity_sold}</td>
                  <td>${detail.total_price}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SalesDetail;
