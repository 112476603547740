import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";
import ShopBookingsDetail from "./shopBookingsDetail.js";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const BookingsShop = () => {
  const shop = useSelector((state) => state.shop);
  const id = shop.shopId;
  const shopName = shop.shopName;
  //const { shopName } = useParams();
  //const { id } = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeBookingId, setActiveBookingId] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (bookingId) => {
    setActiveBookingId(bookingId);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await fetch(`${apiUrl}api/bookings/${id}/`);
        const data = await response.json();
        console.log("data :", data);
        setBookings(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    };

    fetchBookings();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    const formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;
  
    return `${formattedDate} ${formattedTime}`;
  };
  

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Today's Bookings
        </h3>

        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}>
                <th>Client</th>
                <th>Pet</th>
                <th>Start</th>
                <th>End</th>
                <th>Booking Type</th>
                <th>Calendar</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr
                  key={index}
                  style={{ height: "50px", borderBottom: "1px solid #dee2e6" }}
                >
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/booking`}>
                      {booking.owner ? `${booking.owner.FirstName}` : "N/A"}
                    </Link>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.petname ? booking.petname.PetName : "N/A"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(booking.start)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(booking.end)}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.booking_type}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {booking.calendar ? booking.calendar.title : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!activeBookingId ? (
            <div></div>
          ) : (
            <ShopBookingsDetail shopName={shopName} id={activeBookingId} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BookingsShop;
