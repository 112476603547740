import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Button } from "react-bootstrap";
import GaugeChart from "react-gauge-chart";

function Cards() {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState("");

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/financial-cards/`)
      .then((response) => {
        setData(response.data.sale_data);
        console.log("response.data.sales_data : ", response.data.sale_data);
      })
      .catch((error) => {
        console.error("There was an error fetching the psales data!", error);
      });
  }, []);

  return (
    <div>
      <div className="mb-3">
        {data.first_sale_date && data.last_sale_date ? (
          <>
            <span>
              {" "}
              From: {new Date(data.first_sale_date).toLocaleDateString()}{" "}
            </span>
            <span>
              {" "}
              To: {new Date(data.last_sale_date).toLocaleDateString()}{" "}
            </span>
          </>
        ) : (
          "No data available"
        )}
      </div>
      <div className="d-flex justify-content-center">
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <GaugeChart
              id="gross-profit-margin"
              nrOfLevels={20}
              percent={
                data.gross_profit_margin != null
                  ? data.gross_profit_margin / 100
                  : "0"
              }
              textColor="#000000"
              colors={["#32ace8", "#218dc2", "#107f8c"]}
            />
            <Card.Title>Gross Profit Margin</Card.Title>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <GaugeChart
              id="opex-ratio"
              nrOfLevels={20}
              percent={data.opex_ratio != null ? data.opex_ratio / 100 : "0"}
              textColor="#000000"
              colors={["#32ace8", "#218dc2", "#107f8c"]}
            />
            <Card.Title>Opex Ratio</Card.Title>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem", marginRight: "1rem" }}>
          <Card.Body>
            <GaugeChart
              id="net-profit-margin"
              nrOfLevels={20}
              percent={
                data.net_profit_margin != null
                  ? data.net_profit_margin / 100
                  : "0"
              }
              //percent={50/100}
              textColor="#000000"
              colors={["#32ace8", "#218dc2", "#107f8c"]}
            />
            <Card.Title>Net Profit Margin</Card.Title>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <GaugeChart
              id="operating-profit-margin"
              nrOfLevels={20}
              percent={
                data.operating_profit_margin != null
                  ? data.operating_profit_margin / 100
                  : "0"
              }
              textColor="#000000"
              colors={["#32ace8", "#218dc2", "#107f8c"]}
            />
            <Card.Title>Operating Profit Margin</Card.Title>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Cards;
