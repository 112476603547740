import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const CashBalanceChart = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Cash Balance",
        data: [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCashBalance, setTotalCashBalance] = useState(0);
  const [openingBalance, setOpeningBalance] = useState(0);
  const [isOpeningBalancePresent, setIsOpeningBalancePresent] = useState(false);

  const [year, setYear] = useState(new Date().getFullYear());
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  // Function to fetch existing opening balance
  const fetchOpeningBalance = async () => {
    if (!selectedUser || !year) return;

    try {
      const response = await axios.get(`${apiUrl}inventory/opening-balance/`, {
        params: { store: selectedUser, year: year },
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });

      setOpeningBalance(response.data.opening_balance);
      setIsOpeningBalancePresent(response.data.exists);
    } catch (error) {
      console.error("Error fetching opening balance:", error);
    }
  };

  useEffect(() => {
    if (selectedUser && year) {
      fetchOpeningBalance();
    }
  }, [selectedUser, year]);

  const handleSubmitOpeningBalance = async (e) => {
    e.preventDefault();
    if (isOpeningBalancePresent) {
      alert("Opening balance for this year is already set.");
      return;
    }
    try {
      const response = await axios.post(
        `${apiUrl}inventory/opening-balance/`,
        {
          store: selectedUser,
          year: year,
          opening_balance: openingBalance,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      console.log("Opening balance saved:", response.data);
      alert("Opening balance saved");
      fetchCashBalance();
    } catch (error) {
      console.error("Error saving opening balance:", error);
    }
  };

  const fetchCashBalance = async () => {
    try {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let cashBalances = [];
      let months = [];
      let totalBalance = 0;

      for (let month = 1; month <= 12; month++) {
        let response = await axios.get(
          `${apiUrl}inventory/cash-balance/?month=${month}&year=${year}&store=${selectedUser}`
        );
        cashBalances.push(response.data.cash_balance);
        totalBalance += response.data.cash_balance;
        months.push(monthNames[month - 1]);
      }

      setChartData({
        labels: months,
        datasets: [
          {
            label: "Monthly Cash Balance",
            data: cashBalances,
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      });
      setTotalCashBalance(totalBalance);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cash balance data", error);
      setError("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCashBalance();
  }, [year, selectedUser]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchCashBalance();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <h4>Monthly Cash Balance</h4>
          <form
            onSubmit={handleSubmit}
            className="d-flex flex-row justify-content-evenly mb-4 bg-white border-0"
          >
            <div className="d-flex flex-column me-4">
              <label>Year</label>
              <input
                type="number"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                min="2000"
                max={new Date().getFullYear()}
              />
            </div>
            <div className="d-flex flex-column me-4">
              <label>Shop</label>
              <select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <option value="">Select Shop</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.ShopName}
                  </option>
                ))}
              </select>
            </div>
          </form>
          <form
            onSubmit={handleSubmitOpeningBalance}
            className="d-flex flex-row justify-content-evenly mb-4 bg-white border-0"
          >
            <div className="d-flex flex-column me-4">
              <label>Opening Balance</label>
              <input
                type="number"
                value={openingBalance}
                onChange={(e) => setOpeningBalance(e.target.value)}
                disabled={isOpeningBalancePresent} // Disable input if opening balance exists
              />
              {!isOpeningBalancePresent && (
                <button type="submit">Save Opening Balance</button>
              )}
              {isOpeningBalancePresent && (
                <p>Opening balance is already set for this year.</p>
              )}
            </div>
          </form>
          <Line data={chartData} />
          <p>Total Cash Balance for the Year: ${totalCashBalance.toFixed(2)}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CashBalanceChart;
