import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";

/* REACT - REDUX */
import { useDispatch, useSelector } from "react-redux";

const RevenueChart = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [shop, setShop] = useState("");
  const [data, setData] = useState(null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}api/ShowShopView/`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [apiUrl, userInfo.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${apiUrl}inventory/revenue-service/`, {
        start_date: startDate,
        end_date: endDate,
        shop: selectedUser,
      });
      setData(response.data.sale_data);
    } catch (error) {
      console.error("Error fetching revenue data:", error);
    }
  };

  const chartData = {
    labels: [
      "Total Revenue",
      "Total Appointments",
      "Average Revenue per Service",
    ],
    datasets: [
      {
        label: "Revenue Data",
        data: data
          ? [
              data.total_revenue,
              data.total_appointments,
              data.average_revenue_per_service,
            ]
          : [],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4>Revenue Services</h4>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-row justify-content-evenly mb-4 bg-white border-0"
        >
          <div className="d-flex flex-column me-4">
            <label>Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column me-4">
            <label>End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column me-4">
            <label>Shop</label>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option value="all">Select Shop</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.ShopName}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn btn-primary align-self-end">
            Submit
          </button>
        </form>

        {data && (
          <div>
            <ul>
              <li>Total Revenue: ${data.total_revenue.toFixed(2)}</li>
              <li>Total Appointments: {data.total_appointments}</li>
              <li>
                Average Revenue per Service: $
                {data.average_revenue_per_service.toFixed(2)}
              </li>
            </ul>
            <Bar data={chartData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RevenueChart;
