import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ExpenseForm.css";
import { getCookie } from "../../utils/utils";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const ExpenseForm = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL;

  const csrftoken = getCookie("csrftoken");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [dueDate, setDueDate] = useState(new Date());
  const [isFixed, setIsFixed] = useState(true);
  const [isCOGS, setIsCOGS] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState("");

  useEffect(() => {
    axios
      .get(`${apiUrl}inventory/expenses/categories-filter/`)
      .then((response) => {
        setCategories(response.data);
        console.log("categories : ", response.data);
      });
    axios.get(`${apiUrl}inventory/stores/`).then((response) => {
      setStores(response.data);
      console.log("stores : ", response.data);
    });
    axios.get(`${apiUrl}inventory/accounts/bank/`).then((response) => {
      setBankAccounts(response.data);
      console.log("bank accounts : ", response.data);
    });
  }, []);

  useEffect(() => {
    // Fetch subcategories based on selected category
    if (selectedCategory) {
      axios
        .get(
          `${apiUrl}inventory/expenses/subcategories-all/?category=${selectedCategory}`
        )
        .then((response) => {
          setSubcategories(response.data);
        });
    } else {
      setSubcategories([]); // Reset subcategories when no category is selected
    }
  }, [selectedCategory]);

  const resetForm = () => {
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedStore("");
    setSelectedBankAccount("");
    setAmount("");
    setDueDate(new Date());
    setIsFixed(false);
    setIsCOGS(false);
    setPaymentMethod("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedStore || !selectedCategory || !selectedSubCategory) {
      console.error("Please select all required options.");
      return; // Prevents the form from being submitted
    }

    const expenseData = {
      store: selectedStore,
      subcategory_id: selectedSubCategory,
      category: selectedCategory,
      amount: amount,
      due_date: moment(dueDate).format("YYYY-MM-DDTHH:mm:ss"),
      payment_method: paymentMethod,
      is_cogs: isCOGS,
      is_fixed: isFixed,
    };

    if (paymentMethod === "debit_card" || paymentMethod === "transfer") {
      expenseData.bank_account_id = selectedBankAccount;
    }

    console.log("Submitting the following data:", expenseData);

    try {
      const response = await axios.post(
        `${apiUrl}inventory/expenses/`,
        expenseData,
        {
          headers: {
            "X-CSRFToken": csrftoken,
          },
        }
      );
      setSuccessMessage("Your data has been saved.");
      setTimeout(() => {
        setSuccessMessage("");
        resetForm();
      }, 3000);
    } catch (error) {
      console.error("Error submitting the form", error.response.data);
    }
  };

  const renderBankAccountDropdown = () => {
    if (["debit_card", "transfer"].includes(paymentMethod)) {
      return (
        <div>
          <label htmlFor="bankAccount">Bank Account:</label>
          <select
            id="bankAccount"
            value={selectedBankAccount}
            onChange={(e) => setSelectedBankAccount(e.target.value)}
          >
            <option value="" disabled>
              Select a bank account
            </option>
            {bankAccounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.bank_name} - {account.account_number}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <div className="expense-form-container">
          <form onSubmit={handleSubmit}>
            <h2>Record Expense</h2>

            <label htmlFor="store">Store:</label>
            <select
              id="store"
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
            >
              <option value="" disabled>
                Select a store
              </option>
              {stores.map((store) => (
                <option key={store.id} value={store.id} className="text-dark">
                  {store.ShopName}
                </option>
              ))}
            </select>

            <label htmlFor="category">Category:</label>
            <select
              id="category"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="" disabled>
                Select a category
              </option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>

            <label htmlFor="subcategory">SubCategory:</label>
            <select
              id="subcategory"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
            >
              <option value="" disabled>
                Select a subcategory
              </option>
              {subcategories.map((sub) => (
                <option key={sub.id} value={sub.id}>
                  {sub.name}
                </option>
              ))}
            </select>

            <label htmlFor="amount">Amount:</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <label htmlFor="dueDate">Payment Date:</label>
            {/*<DatePicker selected={dueDate} onChange={date => setDueDate(date)} />*/}
            <input
              type="date"
              className="form-control"
              value={dueDate}
              onChange={(event) => setDueDate(event.target.value)}
            />

            <label htmlFor="paymentMethod">Payment Method:</label>
            <select
              id="paymentMethod"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="" disabled>
                Select payment method
              </option>
              <option value="debit_card">Debit Card</option>
              <option value="credit_card">Credit Card</option>
              <option value="cash">Cash</option>
              <option value="transfer">Transfer</option>
            </select>

            {renderBankAccountDropdown()}

            <label htmlFor="fixed">Fixed:</label>
            <input
              type="checkbox"
              id="fixed"
              checked={isFixed}
              onChange={() => setIsFixed(!isFixed)}
            />

            <label htmlFor="cogs">COGS:</label>
            <input
              type="checkbox"
              id="cogs"
              checked={isCOGS}
              onChange={() => setIsCOGS(!isCOGS)}
            />

            <button type="submit">Submit</button>
          </form>
          {successMessage && (
            <div className="success-message">{successMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpenseForm;
