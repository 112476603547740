import React, { useEffect, useState } from "react";
import { Table, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

import { useSelector } from "react-redux";

const InactiveClients = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;

  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("1 month");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/inactive-clients/?filter=${filter}`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, [filter]);

  const createReminder = (clientId) => {
    axios
      .post(`${apiUrl}/api/create-reminder/`, { client_id: clientId })
      .then((response) => {
        alert("Reminder created successfully");

        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === clientId
              ? {
                  ...user,
                  last_reminder_date: new Date().toISOString().split("T")[0],
                }
              : user
          )
        );
      })
      .catch((error) => {
        console.error("There was an error creating the reminder!", error);
        alert("Failed to create reminder");
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Inactive Clients
        </h3>
        <div
          className="px-4"
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
            paddingTop: "20px",
          }}
        >
          <DropdownButton
            className="mb-4"
            id="dropdown-basic-button"
            title={`Filter by time period`}
            onSelect={(eventKey) => setFilter(eventKey)}
          >
            <Dropdown.Item eventKey="1 month">1 Month</Dropdown.Item>
            <Dropdown.Item eventKey="2 months">2 Months</Dropdown.Item>
            <Dropdown.Item eventKey="3 months">3 Months</Dropdown.Item>
          </DropdownButton>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Last Booking</th>
                <th>Last Reminder</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{formatDate(user.last_booking_date)}</td>
                  <td>{user.last_reminder_date}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => createReminder(user.id)}
                    >
                      Remind
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default InactiveClients;
