import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Sidebar from "../Design/TodayBookingSidebar.js";
import Header from "../Header.js";

const BankReconciliation = () => {
  const shop = useSelector((state) => state.shop);
  const shopId = shop.shopId;
  const shopName = shop.shopName;

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [reconciliations, setReconciliations] = useState([]);
  const [stores, setStores] = useState([]);
  const [form, setForm] = useState({
    store: "",
    date: "",
    opening_balance: "",
    sales_in_card: "",
    expenses: "",
    bank_account: "",
    ending_balance: "",
  });

  
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState("");

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (shopId) {
      fetchReconciliations();
    }
  }, [shopId, selectedBankAccount, form.date]);

  const fetchReconciliations = async () => {
    const { store, bank_account, date } = form;
  
    try {
      const response = await axios.get(`${apiUrl}inventory/bank-reconciliations/`, {
        params: {
          store: store,
          bank_account: bank_account,
          date: date,
        },
      });
      console.log("bank recon : ", response.data)
  
      if (response.data.length > 0) {
        // Set the opening balance to the ending balance of the most recent reconciliation
        const previousReconciliation = response.data[0];
        const openingBalance = previousReconciliation.ending_balance;
  
        setForm((prevForm) => ({
          ...prevForm,
          opening_balance: openingBalance,
        }));
      } else {
        setForm((prevForm) => ({
          ...prevForm,
          opening_balance: 0,
        }));
      }
  
      setReconciliations(response.data);
    } catch (error) {
      console.error("Error fetching reconciliations:", error);
    }
  };
  

  const fetchStores = async () => {
    const response = await axios.get(`${apiUrl}inventory/stores/`);
    setStores(response.data);
  };

  const fetchSalesAndExpenses = async (storeId, date) => {
    try {
      const salesResponse = await axios.get(
        `${apiUrl}inventory/get-card-sales/?store=${storeId}&date=${date}`
      );
      const expensesResponse = await axios.get(
        `${apiUrl}inventory/get-card-expenses/?store=${storeId}&date=${date}`
      );
  
      const sales = salesResponse.data.total_sales || 0;
      const expenses = expensesResponse.data.total_card_expenses || 0;
  
      setForm((prevForm) => {
        const newForm = {
          ...prevForm,
          sales_in_card: sales,
          expenses: expenses,
        };
        calculateEndingBalance(newForm.opening_balance, sales, expenses);
        return newForm;
      });
    } catch (error) {
      console.error("Error fetching sales and expenses:", error);
    }
  };
  
  const calculateEndingBalance = (openingBalance, sales, expenses) => {
    const endingBalance =
      parseFloat(openingBalance || 0) +
      parseFloat(sales || 0) -
      parseFloat(expenses || 0);
    setForm((prevForm) => ({
      ...prevForm,
      ending_balance: endingBalance.toFixed(2),
    }));
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };
      if (name === "date" && updatedForm.store) {
        fetchSalesAndExpenses(updatedForm.store, value);
      } else if (
        name === "opening_balance" ||
        name === "sales_in_card" ||
        name === "expenses"
      ) {
        calculateEndingBalance(
          updatedForm.opening_balance,
          updatedForm.sales_in_card,
          updatedForm.expenses
        );
      }
      return updatedForm;
    });
  };  


  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const response = await axios.get(`${apiUrl}inventory/accounts/bank/`);
        setBankAccounts(response.data);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
      }
    };
    fetchBankAccounts();
  }, []);

  const handleBankAccountChange = (event) => {
    const selectedAccount = event.target.value;
    setSelectedBankAccount(selectedAccount);
    setForm({ ...form, bank_account: selectedAccount });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios.post(`${apiUrl}inventory/bank-reconciliations/`, form);
    alert("Entry added.");
    fetchReconciliations();
  };

  return (
    <div className="row">
      <div className="col-md-3" style={{ paddingRight: "0px" }}>
        <Sidebar />
      </div>
      <div className="col-md-9" style={{ paddingLeft: "0px" }}>
        <Header />
        <h3
          style={{ paddingBottom: "10px", fontWeight: "700", color: "#282c34" }}
        >
          Bank Reconciliation
        </h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formStore">
            <Form.Label>Store</Form.Label>
            <Form.Control
              as="select"
              name="store"
              value={form.store}
              onChange={handleChange}
              required
            >
              <option value="">Select Store</option>
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.ShopName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBankAccount">
            <Form.Label>Bank Account</Form.Label>
            <Form.Control
              as="select"
              name="bank_account"
              value={selectedBankAccount}
              onChange={handleBankAccountChange}
              required
            >
              <option value="">Select a Bank Account</option>
              {bankAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.bank_name} - {account.account_number}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={form.date}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formOpeningBalance">
            <Form.Label>Opening Balance</Form.Label>
            <Form.Control
              type="number"
              name="opening_balance"
              value={form.opening_balance}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formSalesInCash">
            <Form.Label>Sales in Card</Form.Label>
            <Form.Control
              type="number"
              name="sales_in_card"
              value={form.sales_in_card}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formExpensesInCash">
            <Form.Label>Expenses</Form.Label>
            <Form.Control
              type="number"
              name="expenses"
              value={form.expenses}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEndingBalance">
            <Form.Label>Ending Balance</Form.Label>
            <Form.Control
              type="number"
              name="ending_balance"
              value={form.ending_balance}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Add Reconciliation
          </Button>
        </Form>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Opening Balance</th>
              <th>Sales in Cash</th>
              <th>Expenses in Cash</th>
              <th>Ending Balance</th>
            </tr>
          </thead>
          <tbody>
            {reconciliations.map((rec) => (
              <tr key={rec.id}>
                <td className="text-center">{rec.date}</td>
                <td className="text-center">${rec.opening_balance}</td>
                <td className="text-center">${rec.sales_in_card}</td>
                <td className="text-center">${rec.expenses}</td>
                <td className="text-center">${rec.ending_balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BankReconciliation;
